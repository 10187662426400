import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"
import './index.css'
import AboutUsPage from './pages/AboutUsPage'
import ContactsPage from './pages/ContactsPage'
import ErrorPage from './pages/ErrorPage'
import MainPage from './pages/MainPage'
import OfferDocumentsPage from './pages/OfferDocumentspage'
import PolicyPrivacyPage from './pages/PolicyPrivacyPage'
import TermsOfService from './pages/TermsOfService'
import TutorsPage from './pages/TutorsPage'


declare global {
  interface Window {
    ym:any;
  }
}

const router = createBrowserRouter([
  {
    path: "/:yclid?",
    element: <MainPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/about/:yclid?",
    element: <AboutUsPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/tutors/:yclid?",
    element: <TutorsPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/contacts/:yclid?",
    element: <ContactsPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/policy_privacy/:yclid?",
    element: <PolicyPrivacyPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/offer_documents/:yclid?",
    element: <OfferDocumentsPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/terms_of_service/:yclid?",
    element: <TermsOfService />,
    errorElement: <ErrorPage />
  },
])

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);


