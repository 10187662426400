// LOAD COMPONENTS
import QuestionButton from "../components/QuestionButton/QuestionButton";
import ScrollToTop from "../components/ScrollToTop";
import WeUseCookie from "../components/WeUseCookie";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import { ReactNotifications } from 'react-notifications-component'
import { useEffect } from "react";
import { getNotifications } from "../api/reqs";
import { showNotification } from "../components/Notifications";

export default function PolicyPrivacyPage() {
    useEffect(() => {
        let wordDayCookie = document.cookie.replace(/(?:(?:^|.*;\s*)wordDayCookie\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        if (wordDayCookie === "") {
            
            getNotifications()
                .then((res) => {
                    showNotification({ type: 'success', title: res.title, desc: res.description })
                })

            let date = new Date;
            date.setMinutes(date.getMinutes() + 360);
            let newDate = date.toUTCString()

            let newCookie = `wordDayCookie=true; expires=${newDate}`
            document.cookie = newCookie;
        }
    }, [])
    return (
        <motion.div initial={{ opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="h-full relative">
            <WeUseCookie />
            <QuestionButton />
            <Header />
            <ScrollToTop />
            <ReactNotifications />

            <main className="py-[100px] max-w-[780px] mx-auto xs:max-md:mx-[20px]">
                <article>
                    <h1 className="md:text-[48px] xs:text-[28px] font-black tracking-tighter mb-[50px]">Публичная оферта</h1>
                </article>

                <section className="flex items-start flex-col justify-center gap-[50px]">

                    <div className="">
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            г. Сочи «10» декабря 2023 г.
                            <br />Индивидуальный предприниматель Большаков Валерий Вячеславович,  ИНН 231909256020, ОГРНИП 323237500351461, именуемое в дальнейшем Исполнитель, адресует настоящий Договор (далее по тексту — «Договор») об оказании услуг по предоставлению через информационно-коммуникационную сеть «Интернет», любому физическому лицу старше 18 (восемнадцати) лет, выразившему готовность воспользоваться Услугами Исполнителя (далее по тексту — «Заказчик»). Исполнитель и Заказчик далее по тексту совместно могут именоваться «Стороны», по отдельности — «Сторона».
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">1. Акцепт оферты</h2>
                        <p className="mb-[20px] text-[#2D2D2D]  md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые AAAAA (далее — Оператор).</p>
                        <p className="text-[#2D2D2D]  md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">1.1. Текст настоящего Договора является публичной офертой (в соответствии со статьей 435 и частью 2 статьи 437 Гражданского кодекса РФ). Настоящий Договор вступает в силу с момента размещения на Сайте. Исполнитель вправе в любое время по своему усмотрению изменить условия Договора или отозвать ее в порядке, предусмотренном настоящим Договором.
                            В случае изменения Исполнителем условий Договора, изменения вступают в силу с момента публикации измененных условий Договора на Сайте, если иной срок не указан Исполнителем в соответствующем уведомлении об изменениях.
                            ______________________
                            В соответствии с пунктом 3 статьи 438 ГК РФ акцепт Договора равносилен заключению Договора на условиях, изложенных в настоящем Договоре.
                            Заказчик считается заключившим с Исполнителем Договор посредством полного и безоговорочного принятия условий настоящего Договора (акцепт) путем совершения в совокупности следующих действий:
                            — ознакомления с условиями настоящего Договора, устанавливающего условия предоставления консультационных услуг, путем письменного  уведомления и направленного в адрес Исполнителя ответа.
                            — выражение Заказчиком согласия с условиями Договора путем внесения оплаты в порядке, определенном в Договоре;
                            <br /> 1.2. Акцепт Заказчиком настоящего Договора означает, что он полностью согласен со всеми положениями настоящего Договора.
                            <br />1.3. С момента акцепта Заказчиком настоящий Договор считается заключенным.
                            <br />1.4. Отношения в области защиты прав потребителей регулируются Гражданским кодексом РФ, Законом «О защите прав потребителей» и принимаемыми в соответствии с ним иными федеральными законами и правовыми актами Российской Федерации.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">2. Предмет договора</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            2.1 В соответствии с настоящим договором Исполнитель обязуется предоставить Заказчику через информационно-коммуникационную сеть «Интернет» языковые консультационные услуги (далее — «Услуги»), а Заказчик обязуется уплатить Исполнителю вознаграждение за Услугу. Услуги Исполнителя заключаются проведении языковых консультаций с использованием веб-интерфейса по средствам вебинаров между Заказчиком и Куратором в целях получения Заказчиком определенной разновидности Услуг, указанных на сайте Исполнителя.
                            <br />2.2. В случае, если Договор заключен Законным представителем несовершеннолетнего лица, оказание Услуг на условиях и в порядке, предусмотренном настоящим Договором, несовершеннолетнему лицу, указанному Заказчиком, является надлежащим исполнением обязательств Исполнителя. Все права Заказчика по такому договору реализуются исключительно Законным представителем, любые уведомления Исполнителя действительны, если они направлены Законному представителю.
                            <br />2.3. Количество Консультаций, проводимых в соответствии с настоящим Договором, определяется количеством оплаченных Заказчиком Консультаций.
                            <br /> 2.4. Методику и формат Консультаций определяет Исполнитель, учитывая пожелания Заказчика, высказанные перед осуществлением оплаты Услуг. Языковые Консультации могут проводиться в форме Индивидуальных или Групповых. Получение Услуг в форме участия в групповых Консультациях, предполагает отсутствие у Заказчика права на перенос, приостановку или замену Куратора при оказании услуг.
                            <br />2.5. Предоставления услуги  Заказчику  предоставляется Исполнителем через веб-интерфейс, после  осуществления Заказчиком оплаты, в порядке, предусмотренном разделом 9 настоящего Договора.
                            <br />2.6. На Заказчика возложена обязанность обеспечить конфиденциальность и безопасность данных предоставленных при поступившей заявки.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">3. Права и обязанности сторон</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            3.1. Исполнитель обязуется:
                            <br /> 3.1.1. В согласованные Сторонами сроки оказать Услуги Заказчику надлежащим образом в соответствии с условиями настоящего Договора.
                            <br /> 3.1.2. Не разглашать Конфиденциальную информацию и данные, предоставленные Заказчиком в связи с исполнением настоящего Договора, не раскрывать и не разглашать такие факты или такую информацию (кроме информации общедоступного характера) какой-либо третьей стороне без предварительного письменного согласия Заказчика.
                            <br /> 3.2. Исполнитель вправе:
                            <br /> 3.2.1. Определять содержание Консультаций.
                            <br /> 3.2.2. Пользоваться услугами любых Кураторов, в целях своевременного и качественного исполнения обязательств по Договору.
                            <br /> 3.2.3. Требовать от Заказчика своевременной оплаты стоимости Услуг в соответствии с условиями настоящего Договора.
                            <br /> 3.2.4. В течение всего периода предоставления доступа Исполнитель оставляет за собой право на замену Куратора и изменения согласованного времени Консультации.
                            <br /> 3.2.5. Исполнитель вправе по своей инициативе перенести одну или несколько Консультаций на другой день, проинформировав при этом Заказчика не менее чем за один календарный день до даты начала переносимой Консультации.
                            <br /> 3.2.6. В течение нерабочих праздничных дней в соответствии с законодательством РФ, Исполнитель оставляет за собой право на перенос Консультаций на следующие за праздниками рабочие дни.
                            <br /> 3.2.7. Вести аудио- или видеозапись Консультаций с целью контроля качества процесса оказания Услуг.
                            <br /> 3.2.8. Приостанавливать работу сайта с целью проведения технических работ, предварительно уведомив об этом Заказчика не позднее чем за 24 часа до проведения соответствующих работ.
                            <br /> 3.2.9. Использовать контактные данные, указанные Заказчиком при регистрации, для отправки информационных и рекламно-информационных материалов, направленных с целью информирования Заказчика о деятельности Исполнителя и ходе исполнения Договора. При отсутствии письменного или устного отказа Заказчика в получении данных материалов, направленного в адрес Исполнителя, согласие на получение данного рода материалов считается подтвержденным.
                            <br /> 3.2.10. Отказать Заказчику в предоставлении и продлении Услуг без объяснения причин отказа.
                            <br /> 3.3. Заказчик обязуется:
                            <br /> 3.3.1. Предоставить Исполнителю все сведения и данные, необходимые для выполнения своих обязательств по настоящему Договору. В эти данные входят:
                            — контактные данные;
                            — персональные данные, запрашиваемые Куратором при проведении Консультаций.
                            <br /> 3.3.2. Производить оплату за предоставление доступа к ресурсу в соответствии с настоящим Договором.
                            <br /> 3.3.3. Не разглашать Конфиденциальную информацию и иные данные, предоставленные Исполнителем в связи с исполнением настоящего Договора, не раскрывать и не разглашать такие факты или информацию (кроме информации общедоступного характера) какой-либо третьей стороне без предварительного письменного согласия Исполнителя.
                            <br /> 3.3.4. Не загружать на сторонние ресурсы сети интернет или иным образом не доводить до всеобщего сведения материалы, являющиеся информационным наполнением (содержанием) Платформы, Консультаций и прочие результаты интеллектуальной деятельности Исполнителя или иных лиц-правообладателей, при отсутствии явным образом выраженного письменного согласия правообладателя/Исполнителя.
                            <br /> 3.3.5. Самостоятельно отслеживать все изменения в условиях настоящего Договора путем ознакомления с их содержанием, расположенным на Сайте.
                            <br /> 3.4. Заказчик вправе:
                            <br /> 3.4.1. Требовать от Исполнителя выполнения его обязательств по настоящему Договору в срок и с надлежащим качеством. Консультация считается проведенной надлежащим образом, если в течение 1 (одного) часа с момента его проведения Заказчик не сообщит Исполнителю о том, что он не состоялся по вине Куратора или Исполнителя.
                            <br /> 3.4.2. Обращаться к Исполнителю по вопросам, касающимся процесса предоставления доступа, получать достоверную информацию об оценке уровня владения иностранным языком.
                            <br /> 3.4.3. Отправить Исполнителю запрос на замену Куратора, сообщив об этом Исполнителю за 72 часа до установленной даты следующей Консультации и аргументировав свой запрос в письменной или устной форме. Правила настоящего пункта не распространяются на отношения сторон в случае участия Заказчика в групповых Консультациях.
                            <br /> 3.4.4. Отказаться от выполнения условий настоящего Договора в случае, если Исполнитель не приступил к исполнению обязательств по настоящему Договору.
                            <br /> 3.5. Заказчик заявляет и гарантирует, что:
                            <br /> 3.5.1. Ознакомился и согласен с условиями настоящей оферты и Тарифами, а также Политикой Конфиденциальности.
                            <br /> 3.5.2. Согласен и не может ссылаться на невозможность исполнения своих обязательств по Договору надлежащим образом, или на невозможность получения услуг от Исполнителя, или отказываться от услуг Исполнителя на основании несогласия с условиями настоящей оферты, Тарифами, и/или Политикой Конфиденциальности по причине их не оформления в письменном виде, скрепленном подписями и печатями Сторон.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">4. Перенос Консультаций и неявка на Консультацию</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            4.1. Перенос или отмена индивидуальных Консультаций Заказчиком могут быть осуществлены не позднее, чем за 24 (двадцать четыре) часа до начала Консультации. Несоблюдение указанного срока приравнивается к неявке Заказчика на Консультацию и оплачивается по полной стоимости. Количество возможных переносов и отмен Консультаций зависит от интенсивности графика Консультаций Заказчика. Заказчик имеет право на:
                            <br /> <b>Переносы:</b>
                            <br />Перенос не более 2 Консультаций в месяц при графике 1 Консультация в неделю*
                            <br />Перенос не более 4 Консультаций в месяц при графике 2 Консультации в неделю*
                            <br />Перенос не более 6 Консультаций в месяц при графике 3 Консультации в неделю*
                            <br /> Перенос не более 8 Консультаций в месяц при графике 4 и более Консультации в неделю*
                            <br />*При условии соблюдения правил отмен и переносов (оповестив не позднее чем за 24 часа до назначенной Консультации)
                            <br /> <b>Отмены:</b>
                            <br /> При графике 1 Консультация в неделю бесплатная отмена не предусмотрена
                            <br /> При графике 2 Консультации в неделю – 1 бесплатная отмена*
                            <br /> При графике 3 Консультации в неделю – 2 бесплатных отмена*
                            <br /> При графике 4 и более Консультации в неделю – 3 бесплатных отмена*
                            <br /> *При условии соблюдения правил отмен и переносов (оповестив не позднее чем за 24 часа до Консультации)
                            <br />4.2. В случае превышения Заказчиком количества возможных переносов и отмен Консультаций, выше указанных в п.п. настоящего Договора в течение календарного месяца, каждый последующий перенос/отмена осуществляется на основании внесения дополнительной оплаты Заказчиком в размере стоимости за одну Консультацию, рассчитанной исходя из количества и стоимости Консультаций, оплаченных Заказчиком на момент такого переноса/отмены. В противном случае, перенос/отмена признаются несостоявшимися, а неявка Заказчика на очередную Консультацию, согласно графику, влечет последствия, предусмотренные п. 4.4. настоящего Договора.
                            <br />4.3. В случае превышения Заказчиком количества возможных переносов и отмен Консультаций, в течение календарного месяца, при условии, что Исполнитель не может связаться с Заказчиком для согласования очередного переноса/отмены Консультации по независящим от Исполнителя причинам, Исполнитель приостанавливает оказание Услуг на условиях, предусмотренных разделом 5 настоящего Договора. В этом случае, Исполнитель имеет право на замену Куратора Заказчика в одностороннем порядке после возобновления Консультаций.
                            <br />4.4. В случае систематических переносов/отмен Консультаций со стороны Заказчика, Исполнитель вправе в одностороннем порядке приостановить оказание Услуг на условиях, предусмотренных разделом 5 настоящего Договора, о чем направляет Заказчику соответствующее уведомление не позднее, чем за 24 часа до предполагаемой приостановки оказания Услуг. В этом случае, Исполнитель имеет право на замену Куратора Заказчика в одностороннем порядке после возобновления Консультаций.
                            <br />4.5. Куратор имеет право переносить Консультации (в случае болезни и по другим уважительным причинам), предупредив об этом Заказчика не менее, чем за 24 часа до назначенного времени Консультации. Если Куратор переносит более 25% Консультаций в месяц или несвоевременно предупреждает о переносах, Заказчик вправе обратиться к Исполнителю с требованием о замене Куратора. Требование о замене Куратора должно быть заявлено Заказчиком не позднее 72 часов до начала очередного согласованного времени Консультации, в противном случае, Исполнитель вправе перенести время Консультации на 72 часа для подбора нового Куратора.
                            <br />4.6. Если в установленное время Заказчик недоступен для звонка Куратора, Куратор повторяет попытки связаться со Заказчиком (не менее трех раз). При этом временем начала Консультации считается установленное в графике Консультаций время Консультаций. Если в результате вышеназванных процедур связаться с Заказчиком не удается, Консультация считается проведенной и оплачивается по полной стоимости.
                            <br />4.7. Если в установленное время начала Консультации плюс 5 (Пять) минут Заказчик не получает звонка от Куратора, он обязан немедленно связаться с Исполнителем. Консультации, которые не состоялись по вине Куратора, переносятся в полном объеме на другое время.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">5. Приостановка оказания Услуг</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            5.1. Заказчик может приостановить оказание Услуг путем направления Исполнителю устного или письменного запроса не позднее чем за 7 (календарных дней) до даты предполагаемой последней Консультации перед перерывом. При этом Заказчик должен сообщить Исполнителю точную дату и время предполагаемой последней Консультации перед перерывом, а также ориентировочную дату первой Консультации после перерыва. Правила настоящего пункта не распространяются на отношения сторон в случае оказания Исполнителем Заказчику Услуг в виде предоставления доступа к групповым (совместным с другими заказчиками) Консультациям.
                            <br /> 5.2. Исполнитель вправе приостановить оказание услуг по собственной инициативе в дни новогодних, рождественских и майских праздников, предварительно предупредив об этом Заказчика путем направления сообщений по электронной почте или телефонного звонка.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">6. Отказ от Услуг</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            6.1. В случае отказа Заказчика от исполнения настоящего Договора Исполнитель возвращает Заказчику денежные средства в размере стоимости не оказанных информационно-консультационных услуг (не проведенных до момента отказа Консультаций). Стороны признают, что указанный размер компенсации является соразмерным понесенным Исполнителем расходам и не подлежит доказыванию в случае возникновения каких-либо споров.
                            <br /> 6.2. В случае осуществления Исполнителем возврата денежных средств, уплаченных Заказчиком в связи с его отказом от Договора, при осуществлении расчета стоимости частично оказанных услуг (проведенных Консультаций) Стороны руководствуются стоимостью за одну Консультацию, определенной в соответствии с Тарифами Исполнителя.
                            <br /> 6.3. В случае расторжения Договора по желанию Заказчика срок возврата денежных средств составляет не более 30 (тридцати) календарных дней.
                            <br /> 6.4. Куратор Заказчика может быть заменен по инициативе Исполнителя, при наличии для этого уважительных причин (временная нетрудоспособность Куратора, другие обстоятельства), о чем Исполнитель сообщает Заказчику по указанным контактным данным. В случае, если Заказчик не захочет выполнять Консультации с другим Куратором, Заказчик вправе приостановить проведение Консультаций в порядке, предусмотренным настоящим Договором.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">7. Порядок направления сообщений</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            7.1. Исполнитель и Куратор могут использовать любые контактные данные Заказчика, из указанных при регистрации, для направления ему информационных сообщений, в том числе об отмене и переносе Консультаций.
                            <br /> 7.2. Для связи с Исполнителем Заказчик должен использовать контактные данные Исполнителя, указанные на странице Сайта https://myinspira.ru .
                            <br /> 7.3. Для связи со своим Куратором Заказчик должен использовать контактные данные Куратора, предоставленные самим Куратором.
                            <br /> 7.4. Сообщения по телефону принимаются в рабочие часы (по будням с 10:00 до 21:00 по московскому времени). Отправленные в другое время сообщения о переносе Консультаций считаются непринятыми.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">7. Порядок направления сообщений</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            7.1. Исполнитель и Куратор могут использовать любые контактные данные Заказчика, из указанных при регистрации, для направления ему информационных сообщений, в том числе об отмене и переносе Консультаций.
                            <br /> 7.2. Для связи с Исполнителем Заказчик должен использовать контактные данные Исполнителя, указанные на странице Сайта https://myinspira.ru .
                            <br /> 7.3. Для связи со своим Куратором Заказчик должен использовать контактные данные Куратора, предоставленные самим Куратором.
                            <br /> 7.4. Сообщения по телефону принимаются в рабочие часы (по будням с 10:00 до 21:00 по московскому времени). Отправленные в другое время сообщения о переносе Консультаций считаются непринятыми.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">8. Стоимость услуг и порядок расчетов</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            8.1. Стоимость Услуг Исполнителя публикуются Исполнителем на Сайте. В цену услуг, оплачиваемых Заказчиком, включается стоимость языковых Консультаций.
                            <br /> 8.2. Исполнитель может в одностороннем порядке изменять стоимость Услуг путем размещения информации на Сайте Исполнителя. При этом стоимость уже оплаченных Заказчиком Услуг не подлежит изменению.
                            <br /> 8.3. Исполнитель по устному соглашению с Заказчиком имеет право изменить стоимость Услуг в том случае, когда Заказчик в устной или письменной форме направил Исполнителю запрос на обеспечение специальных условий языковых Консультаций (нетипичная частота, длительность или время Консультации, выполнение Консультации с определенным Куратором).
                            <br /> 8.4. Оплата Услуг осуществляется в порядке стопроцентной предоплаты. Оплата производится путем внесения денежных средств на предоставленный счет Исполнителя.
                            <br /> 8.5. Если у Заказчика закончились оплаченные Консультации, для продолжения Консультаций он обязан произвести оплату.
                            <br /> 8.6. В случае наличия у Заказчика задолженности по оплате Услуг в количестве 1 (одной) Консультации и более, Исполнитель вправе прекратить оказание Услуг и потребовать от Заказчика возместить денежные средства, равные размеру задолженности.
                            <br /> 8.7. Моментом оплаты считается поступление денежных средств на расчетный счет Исполнителя.
                            <br />  8.8. Заказчик самостоятельно несет ответственность за правильность производимых им платежей.
                            <br /> 8.9. Заказчик самостоятельно оплачивает все услуги организаций связи, необходимые для получения им Услуг Исполнителя.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">9. Системные требования</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            9.1. Исполнитель не отвечает за неоказание услуги в связи с отсутствием у Заказчика необходимого оборудования, программного обеспечения или технических проблем с Интернетом.
                            <br /> 9.2. Минимальные системные требования:
                            <br /> 9.2.1. Оперативная память компьютера: 2 Гб и выше, процессор: 1 ядерный процессор от 1.8 ГГц;
                            <br /> 9.2.2. Операционная система: Windows 7/8/8.1/10, Mac OS X 10.9/10.10/10.11, Ubuntu Linux 9.04/16.04;
                            <br /> 9.2.3. Установленный браузер Google Chrome последней стабильной пользовательской версии с включённым автообновлением;
                            <br /> 9.2.4. Установленная программа Skype последней стабильной версии или другой программы, позволяющей проводить видеоконференции;
                            <br /> 9.2.5. Наличие видеокамеры с разрешением 1 Мп и выше;
                            <br /> 9.2.6. Наличие микрофона;
                            <br /> 9.2.7. Интернет соединение от 10 Мбит/сек.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">10. Ответственность сторон</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            10.1. В случае неисполнения или ненадлежащего выполнения Исполнителем обязанностей по настоящему Договору Заказчик вправе предъявить Исполнителю требование о возврате уплаченных им денежных средств в размере, равном сумме не исполненных Исполнителем обязательств, за удержанием суммы комиссий перевода денежных средств.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">11. Интеллектуальная собственность</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            11.1. Все права на результаты интеллектуальной деятельности (материалы и прочие программы для ЭВМ, разработанные Исполнителем) доступ к которым предоставляется по-настоящему Договору Заказчику, принадлежат Исполнителю и/или его аффилированным лицам и партнерам на законных основаниях и в полном объеме. Использование Заказчиком, материалов и прочих разработанных Исполнителем программных средств и (или) программных обеспечений не означает передачи исключительных прав и (или) прав собственности на указанные результаты интеллектуальной деятельности в каком-либо объеме (полностью или частично) Заказчику и (или) третьим лицам. Никакие права на любое содержимое ресурса, включая, помимо прочего, произведения, текстовые и графические материалы, программы для ЭВМ, товарные знаки не переходят к Заказчику.
                            <br /> 11.2. За исключением использования в объемах и способами, прямо предусмотренными настоящим Договором, Заказчик не вправе использовать материалы (его содержимое и (или) его части).
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">12. Прочие условия</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            12.1. Все Консультации проходят с использованием программ, позволяющим проводить видеоконференции.
                            <br /> 12.2. За невыполнение или ненадлежащее выполнение обязательств по настоящему Договору Стороны несут ответственность в соответствии с условиями настоящего Договора и законодательством Российской Федерации.
                            <br /> 12.3. Стороны не несут ответственности за полное или частичное неисполнение ими своих обязательств по настоящему Договору, если такое неисполнение явилось следствием обстоятельств непреодолимой силы (форс-мажор). Надлежащим подтверждением наличия форс-мажорных обстоятельств и их продолжительности для Сторон является справка, выдаваемая уполномоченными органами.
                            <br /> 12.4. Все споры или разногласия, возникающие между Сторонами по настоящему Договору или в связи с ним, разрешаются путем переговоров между ними. Сторона, чье право нарушено, направляет другой Стороне письменную претензию (требование) посредством электронной связи. Если в течение 15 (Пятнадцати) календарных дней Сторона, направившая претензию, не получила ответ на нее, а также, если в течение 15 (Пятнадцати) календарных дней с момента получения первого ответа на претензию Стороны не пришли к каким-либо договоренностям, то Сторона, чье право нарушено, вправе для защиты своего права обратиться в суд в порядке, установленном законодательством РФ.
                            <br /> 12.5. В случае если одно или более положений настоящего Договора являются по какой-либо причине недействительными, не имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого положения Договора, которые остаются в силе.
                            <br /> 12.6. В течение срока действия настоящего Договора, а также по истечении срока его действия Стороны обязуются не разглашать и не использовать в своих интересах, равно как и в интересах любых третьих лиц, любую конфиденциальную информацию, в том числе деловую, коммерческую, техническую и иную информацию, которая не может быть известна Сторонам из общедоступных источников, переданную одной Стороной другой Стороне и которая стала известной Сторонам в связи с заключением и исполнением настоящего Договора.
                            <br /> 12.7. Заказчик не вправе передавать свои права по Договору третьей Стороне без письменного согласия Исполнителя.
                            <br /> 12.8. Заказчик дает свое согласие Исполнителю на обработку персональных данных Заказчика. Под персональными данными понимается любая информация, относящаяся к Заказчику как к субъекту персональных данных, в том числе: фамилия, имя, отчество, паспортные данные, дата и место рождения, адрес места жительства, контактные данные (номер домашнего, мобильного, рабочего телефонов, адрес электронной почты), а также иная общедоступная информация.
                            <br /> 12.9. Заказчик согласен с тем, что в рамках обработки персональных данных Исполнитель, вправе осуществлять сбор, запись, систематизацию, накопление, анализ, использование, извлечение, распространение, передачу Операторам и / или любым иным третьим лицам (включая, но не ограничиваясь: организациям владельцам-серверов; организациям, оказывающим услуги по осуществлению звонков, смс — рассылок, любых иных видов рассылок и уведомлений; организациям, оказывающим услуги по проведению различных опросов и исследований и пр.), получение, обработку, хранение, уточнение (обновление, изменение), обезличивание, блокирование, удаление, уничтожение его персональных данных путем ведения баз данных автоматизированным, механическим, ручным способами в целях:
                            <br /> — ведения и актуализации клиентской базы;
                            <br /> — получения и исследования статистических данных о качестве оказываемых услуг;
                            <br /> — проведения маркетинговых программ;
                            <br /> — изучения конъюнктуры рынка услуг;
                            <br /> — информирования о предлагаемых Исполнителем услугах, проводимых бонусных мероприятий, акций и т.д.;
                            <br /> — рекламирования и иного любого продвижения услуг на рынке путем осуществления прямых контактов с Заказчиком и иными потребителями;
                            <br /> — технической поддержки при обработке информации, документации и персональных данных с использованием средств автоматизации и без такого использования.
                            <br /> Во всем остальном, что не предусматривает настоящий договор, стороны руководствуются законодательством Российской Федерации.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">Реквизиты Исполнителя</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            ИП Большаков В.В.
                            <br /> ИНН231909256020
                            <br /> ОГРНИП 323237500351461
                            <br /> Р/С40802810500005158285
                            <br /> БАНК АО «ТИНЬКОФФ БАНК»
                            <br /> БИК 044525974
                            <br /> К/С 30101810145250000974
                        </p>
                    </div>

                </section>
            </main>

            <Footer />

        </motion.div>
    );
}
