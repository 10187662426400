import axios from "axios"
import { IAppeals, ICourses, IRewiewCard, ITeachersList, IWordDay } from "./models"

const domen = "https://backend.myinspira.ru/"

interface IQueryParams {
    limit?: number,
    language: string,
    offset: number
}
// GET FUNCS
export const getCourses = async () => {
    const { data } = await axios.get<ICourses[]>(domen + "api/courses/")
    return data
}

export const getContacts = async () => {
    const { data } = await axios.get(domen + "api/contacts/")
    return data
}

export const getTags = async () => {
    const { data } = await axios.get(domen + "api/appeals/tags/")
    return data
}

export const getNotifications = async () => {
    const { data } = await axios.get<IWordDay>(domen + "api/notifications/")
    return data
}

export const getRewiews = async () => {
    const { data } = await axios.get<IRewiewCard[]>(domen + "api/reviews/")
    return data
}

export const getTeachers = async (language?: string) => {
    const { data } = await axios.get<ITeachersList>(domen + `api/teachers/${language ? "?language=" + language + "&" : "?"}limit=50`)
    return data
}

export const getTeachersForId = async (id: number) => {
    const { data } = await axios.get(domen + `api/teachers/${id}/`)
    return data
}

export const getTeachersAudio = async (id: number) => {
    const { data } = await axios.get(domen + `api/teachers/audio/${id}/`)
    return data
}

// POST FUNCS
export const postAppeals = async (data: IAppeals) => {
    return await axios.post(domen + `api/appeals/`, data)
}

export const postVerifyCaptcha = async (key: string) => {
    return await axios.post(domen + `api/appeals/verify-captcha/?token=` + key)
}

export const postFeedback = async (data: { email: string }) => {
    return await axios.post(domen + `api/feedback/`, data)
} 