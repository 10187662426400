// LOAD COMPONENTS
import { ReactNotifications } from 'react-notifications-component'
import { showNotification } from '../components/Notifications';
import { getNotifications } from '../api/reqs';
import { useEffect } from "react";
import { motion } from "framer-motion";
import QuestionButton from "../components/QuestionButton/QuestionButton";
import ScrollToTop from "../components/ScrollToTop";
import WeUseCookie from "../components/WeUseCookie";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function TermsOfService() {
    useEffect(() => {
        let wordDayCookie = document.cookie.replace(/(?:(?:^|.*;\s*)wordDayCookie\s*\=\s*([^;]*).*$)|^.*$/, "$1");

        if (wordDayCookie === "") {

            getNotifications()
                .then((res) => {
                    showNotification({ type: 'success', title: res.title, desc: res.description })
                })

            let date = new Date;
            date.setMinutes(date.getMinutes() + 360);
            let newDate = date.toUTCString()

            let newCookie = `wordDayCookie=true; expires=${newDate}`
            document.cookie = newCookie;
        }
    }, [])
    return (
        <motion.div initial={{ opacity: 0 }} transition={{ duration: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="h-full relative">
            <QuestionButton />
            <Header />
            <WeUseCookie />
            <ScrollToTop />
            <ReactNotifications />

            <main className="py-[100px] max-w-[780px] mx-auto xs:max-md:mx-[20px]">
                <article>
                    <h1 className="md:text-[48px] xs:text-[28px] font-black tracking-tighter mb-[50px] ">Пользовательское соглашение</h1>
                </article>

                <section className="flex items-start flex-col justify-center gap-[50px]">

                    <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                        Настоящее Соглашение определяет условия и порядок использования Пользователями материалов и сервисов сайта «INSPIRA” – лингвистическое пространство», доступного по адресу https://myinspira.ru
                    </p>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">Термины и определения</h2>
                        <p className="mb-[20px] text-[#2D2D2D]  md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            Сайт-Web-сайт, доступный в сети Интернет по адресу  https://myinspira.ru, являющийся составным объектом интеллектуальной̆ собственности в составе программ для ЭВМ и других программных средств, баз данных, графического, аудиовизуального и иного Контента, объединенных в рамках Сайта и использования его возможностей.
                            Сайт состоит из совокупности web-страниц, к каждой из которых в равной степени применяются положения настоящего Соглашения.
                            Интеллектуальные права на Сайт в полном объеме принадлежат Большакову Валерию Вячеславовичу, который администрирует Сайт и доменное имя.
                            <br /><b>Пользователь</b>
                            Посетитель Сайта — лицо, получающее доступ к страницам Сайта с помощью программных и/или технических средств, например, Интернет- браузера.
                            <br /><b>Автор</b>
                            Физическое лицо, в интересах Большакова Валерия Вячеславовича создавшее элементы Контента Сайта.
                            Контент, а также Контент Сайта
                            Информация, представленная в текстовом, графическом, аудиовизуальном форматах на Сайте, являющаяся его наполнением. Контент Сайта в основном состоит из видеолекций, статей и мультимедийных продуктов, размещённых на web-страницах Cайта.
                            К Контенту Cайта относятся статьи, иллюстрации, видеолекции, презентации и иные материалы, права на размещение которых приобретаются Большаковым Валерием Вячеславовичем  у Авторов и/или иных правообладателей.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">1. Общие условия</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            1.1. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.
                            <br /> 1.2. Разместив текст настоящего Пользовательского соглашения на Веб-сайте, Большаков Валерий Вячеславович тем самым делает оферту, адресованную неопределенному кругу лиц и содержащую предложение   считать себя заключившей Пользовательское соглашение с лицом, принявшим оферту (статья 435 ГК РФ). Надлежащим акцептом настоящей оферты согласно пункту 3 статьи 438 ГК РФ является совершение Пользователем действий, направленных на получение доступа к Контенту Сайта. Соответственно, получая доступ к материалам Сайта, Пользователь считается присоединившимся к настоящему Соглашению.
                            <br />  1.3. Срок действия оферты не ограничен.
                            <br /> 1.4. Cайт не является средством массовой информации.
                            <br />  1.5. Администрация Сайта предполагает, что в своё первое посещение Сайта Пользователь прежде получения доступа к Контенту Cайта должен самостоятельно ознакомиться с юридическими аспектами деятельности Cайта и открыть соответствующую ссылку для чтения Правил использования Cайта, следовательно разумно предположить, что любой Пользователь, получивший доступ к Контенту, имеет представление об условиях Пользовательского соглашения, порядке его заключения и исполнения.
                            <br />  1.6. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу незамедлительно с момента размещения новой версии Соглашения на сайте. Пользователи должны самостоятельно следить за обновлениями Правил.
                            <br />  1.7. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
                            <br />  1.8. Пользовательское соглашение действует с даты принятия его условий Пользователем и сохраняет силу на протяжении всего времени, пока Пользователь использует Контент Сайта. В случае, если Пользователь посещает Сайт неоднократно, юридически продолжает действовать Пользовательское соглашение, заключенное при первом посещении Сайта, при этом оно будет изменяться и обновляться одновременно со вступлением в силу новых редакций настоящего Пользовательского соглашения.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">2. Обязательства и Гарантии Пользователя</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            2.1. Пользователь принимает все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений и обязуется их соблюдать
                            <br /> 2.2. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных правах, прав на товарные знаки, знаки обслуживания и наименования мест происхождения товаров, прав на промышленные образцы, прав на использование изображений людей, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
                            <br /> 2.3. Использование материалов Сайта без согласия правообладателей не допускается (статья 1270 ГК РФ) (за исключением использования в учебных, информационных, научных или культурных целях (п. 1 ст. 1274 ГК РФ) с обязательным указанием имени автора и источника заимствования, в том числе: цитирование, использование в качестве иллюстраций (подпункт 1 пункта 1 статьи 1274 Г.К РФ)). В иных случаях для правомерного использования материалов Сайта необходимо заключение лицензионных договоров (получение лицензий) от Правообладателей.
                            <br /> 2.4. Пользователь соглашается с тем, что ему не разрешается предпринимать указанные ниже действия при использовании Сайта, а равно любых его фрагментов:
                            <br /> 2.4.1.модифицировать или иным образом перерабатывать Сайт, в том числе в офлайн режиме, путём копирования html-кода;
                            <br /> 2.4.2 копировать, распространять или перерабатывать материалы и сведения, содержащиеся на Сайте;
                            <br /> 2.4.3 осуществлять инженерный анализ в целях копирования или переработки;
                            <br /> 2.4.4 осуществлять перевод на другие языки;
                            <br /> 2.4.5 осуществлять обратное ассемблирование (декодирование) или какие-либо действия, направленные на восстановление исходного кода либо протоколов программ для ЭВМ, в том числе использованных библиотек;
                            <br /> 2.4.6 нарушать целостность защитной̆ системы или осуществлять какие-либо действия, направленные на обход, снятие или деактивацию технических средств защиты;
                            <br /> 2.4.7 использовать какие-либо программные коды, предназначенные для искажения, удаления, повреждения, имитации или нарушения целостности Сайта, передаваемой информации или протоколов.
                            <br /> 2.5. Комментарии и иные записи Пользователя на Сайте не должны вступать в противоречие с требованиями законодательства Российской Федерации и общепринятых норм морали и нравственности.
                            <br /> 2.6. Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.
                            <br /> 2.7. Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с такой рекламой.
                        </p>
                    </div>

                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">3. Политика по отношению к Персональным данным</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            3.1. В рамках функционирования Сайта Большаков Валерий Вячеславович получает от Пользователей отдельные персональные данные, предусмотренные настоящим Соглашением.
                            <br /> 3.2. Обработка указанных персональных данных регулируется настоящими правилами и осуществляется в соответствии с действующим законодательством (в том числе Федеральным законом «О персональных данных» 152-ФЗ от 27 июля 2006 г.).
                            <br /> 3.3. Принимая обязательство исполнять правила, содержащиеся в настоящем разделе, каждый Пользователь даёт своё согласие на обработку Большаковым Валерием Вячеславовичем его персональных данных в соответствии с пунктом 1 статьи 9 Федерального закона «О персональных данных», а именно на совершение следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, предоставление персональных данных, передача (в том числе путём распространения, доступа), обезличивание, блокирование, удаление и уничтожение.
                            <br /> 3.4. Большаков Валерий Вячеславович осуществляет обработку персональных данных с целью заключения и исполнения настоящего Соглашения, а также с целью выполнения требований действующих нормативно-правовых актов Российской Федерации. Большаков Валерий Вячеславович не осуществляет трансграничную передачу персональных данных. Большаков Валерий Вячеславович вправе совершать автоматизированную обработку персональных данных, в рамках, предусмотренных правилами настоящего Соглашения, а также поручать обработку персональных данных третьим лицам.
                            <br /> 3.5. Большаков Валерий Вячеславович может собирать анонимную неличную информацию, предоставленную Пользователями. К неличной информации относится любая нескрываемая информация, которая становится доступной Большакову Валерию Вячеславовичу во время получения Пользователем доступа к Сайту и его непосредственного использования. Неличная информация может содержать, помимо прочего, данные об идентификации браузера Пользователя, так же как и оперативной системы, порядок посещения страниц, время и дату подключения и т.п. Большаков Валерий Вячеславович может собирать личную информацию, добровольно и сознательно предоставляемую Пользователем во время создания или изменения учётной записи на Сайте, а именно фамилию и имя, номер мобильного телефона и адрес электронной почты, другие сведения, добровольно и сознательно сообщённые Пользователем.
                            <br /> 3.6. Большаков Валерий Вячеславович не несёт ответственности за корректность предоставляемых Пользователями персональных данных.
                            <br /> 3.7. Полученные Большаковым Валерием Вячеславовичем персональные данные не подлежат разглашению, за исключением случаев, когда её раскрытие является обязательным по законодательству Российской Федерации или необходимо для работы Веб-сайта и его функций.
                            <br /> 3.8. Большаков Валерий Вячеславович может использовать полученные персональные данные для следующих целей:
                            <br /> 3.8.1. обеспечивать качественную работу Веб-сайта;
                            <br /> 3.8.2. вносить изменения в Веб-сайт для улучшения его работы;
                            <br /> 3.8.3. отправлять сообщения Пользователям;
                            <br /> 3.8.4. отправлять Пользователям рекламные материалы.
                            <br /> 3.8.5. Сайт может использовать общеотраслевую технологию «куки» (cookies). Куки – это небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере, который использует Пользователь, позволяющий Большакову Валерию Вячеславовичу сохранять персональные настройки и предпочтения Пользователя, а также собирать неличную информацию о нём.
                            <br />   3.9. Большаков Валерий Вячеславович не несёт ответственности за риск наступления неблагоприятных последствий, которые наступят или могут наступить вследствие несоответствия используемого Пользователями оборудования, программного обеспечения или каналов связи установленным требованиям по защите персональных данных от несанкционированного (противоправного) посягательства третьих лиц.
                        </p>
                    </div>


                    <div className="">
                        <h2 className="md:text-[24px] xs:text-xl font-black tracking-tighter mb-[15px]">4. Прочие условия</h2>
                        <p className="text-[#2D2D2D] md:text-base xs:text-[12px] md:leading-[23px] xs:leading-[16px]">
                            4.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.
                            <br /> 4.2. Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма или каких-то иных отношений, прямо не предусмотренных Соглашением.
                            <br /> 4.3. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.
                            <br /> 4.4. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.
                            <br /> Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.
                        </p>
                    </div>

                </section>
            </main>

            <Footer />

        </motion.div>
    );
}
